<script>
export let items = [];
</script>

<ul class="BulletItems">
  {#each items as text (text)}
    <li>{text}</li>
  {/each}
</ul>

<style lang="scss">
  ul.BulletItems {
    list-style: none;
    width: 300px;
    display: flex;
    flex-wrap: wrap;

    li {
      display: inline-block;
      position: relative;
      white-space: nowrap;
      line-height: 15px;
      letter-spacing: 1px;
      color: $text-hl;
      font-size: 13px;
      font-weight: 800;
      font-family: $font_reg;

      &:nth-child(1), 
      &:nth-child(2), 
      &:nth-child(4) {
        &:after {
          display: inline-block;
          transform: translateY(-0.1em);
          content: "";
          border-radius: 0.375rem;
          height: 0.3rem;
          width: 0.3rem;
          margin-left: 0.42rem;
          margin-right: 0.42rem;
          background-color: white;
        }
      }
    }
  }
</style>
