<script>
import ArrowButton from "$lib/components/ArrowButton.svelte";

export let desktopOnly = true;
export let target;
export let offset = 0;

function scrollToTarget () {
  if (!target) return;

  window.scrollTo({
    top: target.offsetTop + offset,
    behavior: "smooth"
  })
}
</script>


<section class="IconSection" class:desktop-only={desktopOnly}>
  <ArrowButton on:click={scrollToTarget} />
</section>


<style lang="scss">

.IconSection {
  display: flex;
  align-items: center;
  justify-content: center;

  &.desktop-only {
    @include mobile {
      display: none;
    }
  }

  @include mobile  { padding-top: $spacing_iconsection_mobile; }
  @include tablet  { padding-top: $spacing_iconsection_tablet; }
  @include desktop { padding-top: $spacing_iconsection_desktop; }
}

</style>
