<script>
import Modal, {getModal}  from "$lib/components/Modal.svelte";
import VimeoModal         from "$lib/components/VimeoModal.svelte";
import VimeoPlayer        from "$lib/components/VimeoPlayer.svelte";
import HomepageHeroMobile from "$lib/components/HomepageHeroMobile.svelte";
import BannerHeroOverlay  from "$lib/components/BannerHeroOverlay.svelte";

let parallax = 0;

const modalName = 'banner';

let windowWidth;
let tabletOrUp = true;
$: tabletOrUp = windowWidth >= 768;

export let trailerVid, fullVid, poster;

function play () {
  getModal(modalName).open();
}
</script>


<svelte:window bind:outerWidth={windowWidth} on:scroll={() => parallax = window.pageYOffset} />

<section class="HeroSection">
  {#if tabletOrUp}
    <div class="VimeoWrapper">
      <div class="VimeoBackground">
        <div class="VimeoParallax" style="transform:translateY(calc(-50% + { parallax/2 }px))">
          <VimeoPlayer {poster} videoId={trailerVid.vid} autoplay loop background />
        </div>
      </div>

      <!-- <BannerHeroOverlay on:play={play} /> -->
    </div>
  {:else}
    <VimeoPlayer videoId={trailerVid.vid} autoplay loop background />

    <HomepageHeroMobile on:play={play} />
  {/if}
</section>


<VimeoModal controls id={modalName} video={ fullVid } />


<style lang="scss">
.HeroSection {
  overflow: hidden;
  position: relative;

  @include mobile {
    height:  52.56vw;
    margin-top: 6rem;
    margin-bottom: 3rem;
  }

  @include tablet_and_desktop {
    height:  52.56vw;
    max-height: 80vh;
  }

  // Try to stop Vimeo player jumping the page layout
  :global(.VimeoPlayer:not([data-vimeo-initialized])) {
    min-height: 55.4vw;
  }

  :global(.VimeoPlayer) {
    width: 100%;
  }
}

.VimeoWrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
}

.VimeoBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.VimeoParallax {
  position:absolute;
  top:50%;
  width:100%;
}

</style>
