<script>
  import ArrowButton from "$lib/components/ArrowButton.svelte";

  export let link = null;
  export let target;
  export let offset = 0;

  function scrollToTarget () {
    if (!target) return;

    window.scrollTo({
      top: target.offsetTop + offset,
      behavior: "smooth"
    })
  }
</script>


<div class="ArrowLinkTitle">
  <h2> <slot /> </h2>
  {#if link}
    <ArrowButton on:click={scrollToTarget} />
    <a href={link.url}>{ link.text } &nbsp; →</a>
  {/if}
</div>


<style lang="scss">
  .ArrowLinkTitle {
    display: flex;
    flex:    1;
    justify-content: space-between;

    :global(.ArrowButton) {
      @include tablet_and_desktop {
        display: none;
      }
    }

    h2 {
      display:     inline;
      color:       white;
      font-family: $font_reg;
      font-weight: 400;
    }

    a {
      color:       $text-hl;
      font-family: $font_reg;
    }
  }
</style>
