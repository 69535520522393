<script>
export let study = null;

let shouldRenderVideo = study.featured_teaser_video && typeof study.featured_teaser_video.url !== 'undefined';

// State
let show;
let video;

function playTeaser () {
  show = true;
  video.currentTime = 0;
  video.play();
}

function stopTeaser () {
  show = false;
  setTimeout(() => { video.pause(); }, 400);
}
</script>


<a class="CaseStudyPreview" href={`work/${study.uid}`}>
  <div class="FeaturedItemContainer">
    <div class="ImageVideoContainer" role="presentation" on:mouseenter={playTeaser} on:mouseleave={stopTeaser}>
      <div class="FeaturedVideoAspectImage" class:has-video={shouldRenderVideo}>
        {#if shouldRenderVideo}
          <video class="FeaturedVideo" class:show bind:this={video}
                 playsInline muted loop
                 src={study.featured_teaser_video.url} />
        {/if}

        <img src={study.featured_image ? study.featured_image.url : ""} alt={study.title} />
      </div>
    </div>

    <div class="ProjectDescription">
      <p class="ProjectTitle">{study.title}</p>
      <p class="CompanyName">{study.client}</p>
    </div>
  </div>
</a>


<style lang="scss">
  .ImageVideoContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 1166/438;
  }

  .FeaturedItemContainer {
    width: 100%;
    cursor: pointer;
    padding-top: 2.44rem;
  }

  .ProjectDescription {
    padding-top: 1.625rem;

    .ProjectTitle {
      color: $text-main;
    }

    .CompanyName {
      color:       $text-hl;
      padding-top: 0.5rem;
    }

    @include mobile {
      line-height: 1.25em;
    }
  }

  .FeaturedVideo {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    transition: opacity 0.4s ease-out;
  }

  .FeaturedVideoAspectImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      opacity: 1;
      object-fit: cover;
      transform: translateZ(0) scale(1);
      transition: opacity 0.4s ease-in, transform 0.4s ease;
    }

    &.has-video img:hover {
      opacity: 0;
      transform: translateZ(0) scale(1.02);
    }
  }
</style>
