<script>
import PlayButton  from "$lib/components/PlayButton.svelte";
import Constrain   from "$lib/components/Constrain.svelte";
import BulletItems from "$lib/components/BulletItems.svelte";

export let subtitles = [
  "VFX",
  "MOTION DESIGN",
  "EXPERIENTIAL",
  "LIGHT ART",
  "EXTENDED REALITY",
];
</script>


<div class="HomepageHeroMobile">
  <Constrain>
    <h1>Creature Post</h1>

    <BulletItems items={subtitles} />

    <PlayButton on:play> Play Reel </PlayButton>
  </Constrain>
</div>


<style lang="scss">
  .HomepageHeroMobile {
    position: relative;

    :global(.PlayButton) {
      margin-top: 3.2rem;
    }

    :global(.Constrain) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 3rem auto;
    }
  }

  h1 {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
</style>
