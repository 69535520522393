<script>
  import Section          from "$lib/sections/Section.svelte";
  import HeroSection      from "$lib/sections/HeroSection.svelte";
  import ScrollToContent  from "$lib/sections/ScrollToContent.svelte";
  import CaseStudyPreview from "$lib/components/CaseStudyPreview.svelte";
  import ArrowLinkTitle   from "$lib/components/ArrowLinkTitle.svelte";
  import GradientLine     from "$lib/components/GradientLine.svelte";

  export let data;
  const { page } = data;
  const { featured } = page;

  let scrollTarget;

  $: {
    featured.map(study => {
      if (!study.featured_teaser_video) {
        console.warn(`Featured Case Study '${study.title}' does not set a teaser video`);
      }
    });
  }
</script>


<svelte:head>
  {#each featured as study}
    {#if study.featured_teaser_video }
      <link as="video" rel="prefetch" href={study.featured_teaser_video.url} />
      <link as="image" rel="prefetch" href={study.hero_image.url} />
    {/if}
  {/each}
</svelte:head>

<HeroSection poster={page.banner_image} trailerVid={page.hero_teaser_vimeo_video} fullVid={page.main_video} />
<ScrollToContent desktopOnly={true} target={scrollTarget}/>
<div bind:this={scrollTarget} />

<Section featured={!!featured}>
  <ArrowLinkTitle target={scrollTarget} offset={-75} link={{ text: "All Work", url: "/work" }}> Featured </ArrowLinkTitle>
  <GradientLine />

  {#each featured as study (study.uid)}
    <CaseStudyPreview study={study} />
  {/each}
</Section>
